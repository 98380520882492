<!DOCTYPE html>
<html lang="{{ lang }}">
<head>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width,initial-scale=1">
  <meta name="generator" content="VuePress {{ version }}">
  <script>
		(function() {
      const userMode = localStorage.getItem('vuepress-reco-color-scheme') || 'auto';
			const systemDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

			if (userMode === 'dark' || (userMode === 'auto' && systemDarkMode)) {
				document.documentElement.classList.toggle('dark', true);
			}
		})();
	</script>
</head>
<body>
	<div id="app"></div>
<script type="module">
import '@vuepress/client/app'
</script>
</body>
</html>
